:root {
  /* Colors: */
  --negro: #5A5A5A;
  --gris-muy-claro: #F5F5F5;
  --gris-claro: #D6D6D6;
  --gris-azulado-claro: #97A2B5;
  --gris-azulado-oscuro: #65738d;
  --gris-oscuro: #707070;
  --azul: #1696D5;
  --azul-oscuro: #0084a2;
  --gris-medio: #8791A2;
  --rojo: #FD5858;
  --rojo-oscuro: #c53838;
  --gris-azulado-92abc7: #92ABC7;
  --blanco: #FFFFFF;
  --blanco-azulado: #F3F5F7;
  --morado: #8365E6;
  --morado-oscuro: #6950ba;
  --admision1: #005b72;
  --admision2: #0084a2;

  /* Colores Línea de Tiempo */
  --body-bg: #ffffff;
  --text-color: #373737;
  --gray-base: #494949;
  --gray-darker: #222;
  --gray-dark: #333;
  --gray: #555;
  --gray-light: #777;
  --gray-lighter: #eee;
  --brand-primary: #1f9eba;
  --brand-success: #59ba1f;
  --brand-info: #5bc0de;
  --brand-warning: #d1bd10;
  --brand-danger: #ba1f1f;
}

@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Ubuntu+Condensed&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&family=Arvo:ital,wght@0,400;0,700;1,400;1,700&display=swap');
/*
.app{
  background-color: #ffffff;
  box-shadow: 0px 0px 10px #748BBF1D;
  border-radius: 6px;
  font-family: 'Ubuntu', sans-serif;
  color: var(--negro);
  font-size: 14px;
}


.subtitulo-app{
  font-family: 'Ubuntu', sans-serif;
  font-weight: 400;
  font-size: 15px;
  color: var(--gris-oscuro);
}

.menu-app li{
  padding:0px;
}

.menu-app a{
  font-family: 'Ubuntu', sans-serif;
  font-weight: 200;
  font-size: 15px;
  color: var(--gris-azulado-oscuro);
}

.menu-app .activo{
  background-color: rgba(223, 236, 246, 0.8);

}

.menu-app i{
  color: var(--gris-medio)
}

.app form label{
  padding-top: 8px;
}

.app form input{
  font-size: 14px;
}

.app form select{
  font-size: 14px;
}


.titulo-app{
  font-family: 'Ubuntu', sans-serif;
  font-weight: 400;
  font-size: 24px;
  color: var(--negro);
}

.icono-app i{
  
  color: var(--gris-azulado-oscuro);
  font-size:20px;
}
*/ 

/* Matriculas */
.matricula .titulo{
  font-family: 'Ubuntu', sans-serif;
  font-weight: 400;
  font-size: 18px;
  color: var(--negro);
  background-color: #1696D5;
  border-radius: 5px;
  padding-left: 10px;
  color:#FFFFFF;
}

.matricula label{
  min-width: 100px;
  padding-right: 10px;
}

.matricula .dv label{
  min-width: 10px;
  padding-right: 10px;
}

/* SIDEBAR */
.sidebar {
  background-color: var(--azul-oscuro);
  overflow-y: auto;
  color: #ffffff;
  
}

.sidebarmini li i{
  padding: 5px 0 5px 0;
}

.sidebar li a{
  color:#FFFFFF;
  font-family: 'Ubuntu', sans-serif;
  font-weight: 200;
  font-size: 13px;
  margin-bottom: 5px;
  line-height: 25px;
  padding: 4px 8px 4px 8px;
}


.sidebar .nav-subitems{
  padding-left: 20px;
}

.sidebar li a:hover, .sidebar li a:active, .sidebar li a:focus{
  color: #FFFFFF;
  padding-left: 10px;
}

.sidebar .activo {
  background-color: #08415e7a;
}

.sidebar.expandida {
  width: 240px;
}

.sidebar.contraida {
  width: 60px;
}

.sidebar .logo{
  height: 60px;
}
.ocultar {
  display: none !important;
}
.mostrar {
  display:block !important;
}
/*
.main {
  background-color: var(--blanco-azulado);
  overflow-y: auto;
  display:flex;
  width: calc(100vw - 240px);
}

.main.expandida {
  width: calc(100vw - 60px);
}
*/
/* botones */
.btn-mini{
  height: 32px;
  line-height: 16px;
  font-size: 15px;
  font-weight: 400;
}

.btn-azul {
  background-color: #1696D5;
  color: #FFF;
}

.btn-azul:hover{
  background-color: #0084a2;
  color: #FFF;

}

.btn-rojo {
  background-color: var(--rojo);
  color: #FFF;
}

.btn-rojo:hover{
  background-color: var(--rojo-oscuro);
  color: #FFF;

}

.btn-gris {
  background-color: var(--gris-azulado-claro);
  color: #FFF;
}

.btn-gris:hover{
  background-color: var(--gris-azulado-oscuro);
  color: #FFF;

}

.btn-morado {
  background-color: var(--morado);
  color: #FFF;
}

.btn-morado:hover{
  background-color: var(--morado-oscuro);
  color: #FFF;

}

/* animaciones */

.sidebar, .main, .navbar {
  transition: 0.3s;
}

.sidebar li{
  transition: 0.3s;
}

/* PRE MATRICULA TESTIMONIOS */

.panel-testimonio {
  font-family: 'Ubuntu', sans-serif;
}

.preinscripcion .panel-testimonio{
  background-color: #53aaca;
  /*min-height: 150vh;*/
}

.preinscripcion .panel-preinscripcion{
  background-color: #FFFFFF;
  /*height: 100vh;*/
  margin-bottom: 10px;
  margin-left: 100px; /* 25-09-2023 */
}

.preinscripcion .card{
  font-size: 12px; 
  font-family: 'Ubuntu', sans-serif;
  color: var(--gris-azulado-oscuro);
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 7px;
}

.preinscripcion .card-title{
  font-size: 13px;
  margin-bottom: 0px;
}

.panel-preinscripcion h4{
  font-family: 'Ubuntu', sans-serif;
  font-weight: 400;
  color: var(--admision2);
}


.panel-preinscripcion h5{
  font-family: 'Ubuntu', sans-serif;
  font-weight: 400;
  color: var(--azul);
}

.panel-preinscripcion .progress-bar{
  background-color: var(--admision2);
}

.panel-preinscripcion .formulario{
  min-height: 350px;
}

.custom-progress-bar{
  height: 10px;
}

.formulario input{
  font-size: 14px;
}

.formulario select{
  font-size: 14px;
}

.quotations::before,
.quotations::after {
font-family: 'Ubuntu', sans-serif;
content: '"';
position: absolute;
font-size: 90px;
font-weight: bold;
color: #6592B2;
}

.quotations::before {
top: -40px;
left: 20px;
}

.quotations::after {
bottom: -90px;
right: 20px;
}

.nav-pills-progress .nav-link {
  position: relative;
}

.nav-pills-progress .nav-link::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: #007bff;
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.nav-pills-progress .nav-link.active::before {
  transform: scaleX(1);
}

.hidden-tab {
  display: none;
}

/* FINAL */
.wrapper{
  display:flex;
}

.checkmark__circle{
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 4;
  stroke-miterlimit: 10;
  stroke: #21b124;
  fill: none;
  animation: stroke 1.5s cubic-bezier(0.65, 0, 0.45, 1) forwards
}

.checkmark{
  width: 35px;
  height: 35px;
  border-radius: 50%;
  /*display: block;*/
  stroke-width: 4;
  stroke: #fff;
  stroke-miterlimit: 10;
  box-shadow: inset 0px 0px 0px #21b124;
  animation: fill 0.75s ease-in-out 1.5s forwards, scale 0.75s ease-in-out 2.25s both
}

.checkmark__check{
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 1.5s cubic-bezier(0.65, 0, 0.45, 1) 1.5s forwards
}
@keyframes stroke{
  100%{
      stroke-dashoffset: 0
  }
}
@keyframes scale{
  0%, 100%{
      transform: none
  }
  50%{
      transform: scale3d(1.1, 1.1, 1)
  }
}
@keyframes fill{
  100%{
      box-shadow: inset 0px 0px 0px 30px #21b124
  }
}

/* MENSAJE FINAL */
.mensaje-final h4{
  font-family: 'Ubuntu', sans-serif;
  font-weight: 400;
  color: var(--admision2);
}

.mensaje-final h5{
  font-family: 'Ubuntu', sans-serif;
  font-weight: 400;
  color: var(--azul);
}

.mensaje-final-op h6{
  font-family: 'Ubuntu', sans-serif;
  font-weight: 400;
  color: var(--azul);
}

/* FOOTER */
.footer {
  background-color: #004458;
}

.footer label {
  color: #FFFFFF;
  font-size: 14px;
}

.titulofoo {
  padding: 1em;
  color: #FFFFFF;
  font-family: 'Ubuntu', sans-serif;
  font-weight: 400;
}

/* AREAS */
.areas-titulo h2 {
  text-align: center; 
  color: #0b4c5f;
  font-weight: 700;
}

.areas-titulo h4{
  font-family: 'Ubuntu', sans-serif;
  font-weight: 400;
  color: var(--admision2);
}

.areas-titulo h5{
  font-family: 'Ubuntu', sans-serif;
  font-weight: 400;
  color: var(--azul);
}

.areas-items {
  padding: 15px; 
  border: solid 1px #ebebeb; 
  float: left; 
  width: 100%; 
  border-radius: 10px; 
  margin-bottom: 10px; 
  background-color: #f8f8f8; 
  margin-top: 20px;
}

.areas-items-link {
  color: #1696D5;
  text-decoration: none;
}

.areas-items-link:hover {
  color: #0084a2;
  text-decoration: none;
}

.desva { 
  opacity : 0; 
  transition: opacity 0.2s;
}

.desva2 { 
  opacity : 1;
}


@media only screen and (max-width: 600px) {
  .ocultarCel {
    display: none;
  }
}

body {
  overflow-x: hidden;
}

.btn-celeste {
  background-color: #65b5e6;
  color: #FFF;
}

.btn-celeste:hover{
  background-color: #3396c6;
  color: #FFF;

}