.toast-success-container {
  color: #000 !important;
  border-radius: 8px !important;
  background: #FFFFFF !important;
  border: 1px solid #07bc0c !important;
  box-shadow: 0px 1px 5px rgba(248, 175, 175, 0.1) !important;
}

.toast-success-container-after {
  overflow: hidden;
  position: relative;
}

.toast-success-container-after::after{
  top: 0;
  left: 0;
  content: '';
  width: 7px;
  height: 100%;
  position: absolute;
  display: inline-block;
  background-color: #07bc0c;
}

.toast-error-container {
  color: #000 !important;
  border-radius: 8px !important;
  background: #FFFFFF !important;
  border: 1px solid #e74c3c !important;
  box-shadow: 0px 1px 5px rgba(248, 175, 175, 0.1) !important;
}

.toast-error-container-after {
  overflow: hidden;
  position: relative;
}

.toast-error-container-after::after{
  top: 0;
  left: 0;
  content: '';
  width: 7px;
  height: 100%;
  position: absolute;
  display: inline-block;
  background-color: #e74c3c;
}